@import "./less.less";
@import "../defaults/css.less";

:global {
	:root {
		--font-family-regular: @fontFamilyRegular;
		--font-family-demi-bold: @fontFamilyDemiBold;
		--font-family-bold: @fontFamilyBold;
		--font-weight-regular: @fontWeightRegular;
		--font-weight-demi-bold: @fontWeightDemiBold;
		--font-weight-bold: @fontWeightBold;

		--font-transform: @fontTransform;

		.light {
			// Background
			--background-primary: #fff;
			--background-primary-inverse: #232323;
			--background-primary-translucent: rgba(#fff, 0.9);
			--background-secondary: #f7f7f7;
			--background-tertiary: #fff;
			--background-quaternary: #f7f7f7;

			/* HEADER */
			--header-background: #000000;
			--header-pull-background: #000;
			--header-border-color: #000;
			--header-icon-color: #fff;

			// Font
			--font-primary: #222222;
			--font-secondary: #fff;
			--font-disabled: rgba(#222222, 0.3);
			--font-neutral-white1: #fff;
			--font-neutral-black1: #222222;
			--font-neutral-grey1: #8F8F8F;


			// Icon
			--icon-default: #222222;
			--icon-active: #222222;
			--icon-disabled: rgba(#222222, 0.3);
			--icon-neutral-white1: #fff;
			--icon-neutral-black1: #222222;
			--icon-neutral-grey1: #f7f7f7;
			--icon-neutral-inverse: #fff;

			// Neutrals
			--white1: #fff;
			--white2-disabled: rgba(#fff, 0.3);
			--black1: #222222;
			--black2-disabled: rgba(#222222, 0.3);
			--neutral1-borders-and-stroke: #e7e7e7;
			--neutral2-separator-and-divider: rgba(#232323, 0.1);
			--neutral3-background-fill: #f0f0f0;
			--neutral4-grey: #999999;
			--neutral6-grey-fill: #dcdcdc;
			--neutral7-neutral-fill: #222222;
			--neutral8-neutral-fill2: #fff;

			// Menu
			--menu-default: #222222;
			--menu-active: #e84e1e;
			--menu-disabled: rgba(#222222, 0.3);

			// Colors
			--menu-selection: #004b78;
			--primary1: #004b78;
			--primary1-disabled: rgba(#004b78, 0.3);
			--primary2: #2c4973;
			--primary2-disabled: rgba(#2c4973, 0.3);
			--accent1: #e84e1e;
			--accent2: rgba(#e05432, 0.5);
			--accent3: #4a90e2;
			--accent4: #62ac29;
			--alert-info: #1375e7;
			--alert-warning: #ffdb4d;
			--alert-warning2: #ea4b1f;
			--gridlines: #ff3333;

			// Material Fills
			--light1: rgba(#fff, 0.1);
			--dark1: rgba(#232323, 0.5);
			--dark2: rgba(#000, 0.5);

			// Select
			--select-background-primary: #fff;
			--select-background-secondary: #fff;
			--select-options-background-primary: #f0f0f0;
			--select-options-background-secondary: #fff;

			// Input Slider
			--input-slider-accent: #65acff;
			--input-slider-background: rgba(#222222, 0.1);
			--input-slider-handle-border: #fff;
		}

		.dark {
			// Background
			--background-primary: #181818;
			--background-primary-inverse: #fff;
			--background-primary-translucent: rgba(#181818, 0.9);
			--background-secondary: #232323;
			--background-tertiary: #181818;
			--background-quaternary: #181818;

			/* HEADER */
			--header-background: #000000;
			--header-pull-background: #000;
			--header-border-color: #000;
			--header-icon-color: #fff;

			// Font
			--font-primary: #fff;
			--font-secondary: #222222;
			--font-disabled: rgba(#fff, 0.3);
			--font-neutral-white1: #fff;
			--font-neutral-black1: #222222;
			--font-neutral-grey1: #8F8F8F;

			// Icon
			--icon-default: #fff;
			--icon-active: #222222;
			--icon-disabled: rgba(#fff, 0.3);
			--icon-neutral-white1: #fff;
			--icon-neutral-black1: #222222;
			--icon-neutral-grey1: #f7f7f7;
			--icon-neutral-inverse: #222222;

			// Neutrals
			--white1: #fff;
			--black1: #222222;
			--neutral1-borders-and-stroke: #e7e7e7;
			--neutral2-separator-and-divider: rgba(#fff, 0.1);
			--neutral3-background-fill: #f0f0f0;
			--neutral4-grey: #999999;
			--neutral5-slider-step: #e4e4e4;
			--neutral6-grey-fill: #dcdcdc;
			--neutral7-neutral-fill: #fff;
			--neutral8-neutral-fill2: #232323;

			// Menu
			--menu-default: #fff;
			--menu-active: #e84e1e;
			--menu-disabled: rgba(#fff, 0.3);

			// Colors
			--primary1: #004b78;
			--primary2: #2c4973;
			--accent1: #e84e1e;
			--accent2: #e05432;
			--accent3: #4a90e2;
			--accent4: #62ac29;
			--alert-info: #1567e0;
			--alert-warning: #ffdb4d;
			--alert-warning2: #ea4b1f;
			--gridlines: #ff3333;

			// Material Fills
			--light1: rgba(#fff, 0.1);
			--dark1: rgba(#232323, 0.5);
			--dark2: rgba(#000, 0.5);

			// Select
			--select-background-primary: #222222;
			--select-background-secondary: #181818;
			--select-options-background-primary: #222222;
			--select-options-background-secondary: #181818;

			// Input Slider
			--input-slider-accent: #65acff;
			--input-slider-background: #f0f0f0;
			--input-slider-handle-border: #f0f0f0;
		}
	}
}
